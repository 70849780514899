import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, filter, retry} from 'rxjs/operators';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private socket?: WebSocketSubject<any>;
  private readonly fallbackUrl?: string;
  private readonly webSocketUrl?: string;

  constructor() {
    this.webSocketUrl = environment.websocketUrl;
    const backendUrl = window['data']?.publication?.backendUrl;
    if (backendUrl) {
      this.fallbackUrl = this.transformUrl(backendUrl);
    }
  }

  get hotspotEvents(): Observable<any> {
    if (!this.socket) {
      this.socket = this.create();
    }

    return this.socket
      .pipe(
        filter(message => {
          if (message.messageType === 'HotspotChangedEvent' || message.messageType === 'HotspotCreatedEvent' || message.messageType === 'HotspotDeletedEvent') {
            return true;
          } else if (message.messageType === 'welcome' && message.authenticated) {
            return false;
          }
          return false;
        }),
        retry()
      );
  }

  private create(): WebSocketSubject<any> {
    return this.createWebSocket(this.webSocketUrl).pipe(
      catchError(() => {
        return this.createWebSocket(this.fallbackUrl);
      })
    ) as WebSocketSubject<any>;
  }

  private createWebSocket(url: string): Observable<WebSocketSubject<any>> {
    return webSocket(url);
  }

  private transformUrl(url?: string): string {
    const baseUrl = new URL(url);
    const protocol = baseUrl.protocol === 'https:' ? 'wss:' : 'ws:';
    return `${protocol}//${baseUrl.host}/ws/events`;
  }

  subscribeToHotspotEvents(assetIds: number[]) {
    if (this.socket) {
      const message: any = {
        messageType: 'subscription',
        subscriptions: [
          {
            eventType: ['HotspotChangedEvent', 'HotspotCreatedEvent', 'HotspotDeletedEvent'],
            assetIds: assetIds,
            subscriptionType: 'HotspotSubscription'
          }
        ]
      };
      this.socket.next(message);
    }
  }

  closeSocket() {
    this.socket!.unsubscribe();
    this.socket = undefined;
  }
}
