import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {SpoUrlService} from "./spo-url.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DraftsService {
  assetId: string;
  baseUrl: string;
  scopeKey: string;

  constructor(private httpClient: HttpClient, spoUrlService: SpoUrlService) {
    this.assetId = spoUrlService.assetId;
    this.baseUrl = spoUrlService.backendUrl;
    this.scopeKey = spoUrlService.scopeKey;
  }

  getDraftConfiguration(): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}scope/${this.scopeKey}/asset/${this.assetId}/format/p2fdocumentdraft/content/data/config.json`)
  }

  getDraftHotspots(): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}scope/${this.scopeKey}/asset/${this.assetId}/format/p2fdocumentdraft/content/data/hotspots.json`)
  }

}
