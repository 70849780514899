import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import * as MobileDetect from 'mobile-detect';

import {WINDOW} from '@page2flip/core';
import {
  Configuration,
  Design,
  Dimensions,
  Features,
  Layout,
  Legal,
  Options,
  PageMarker,
  Shop
} from '@page2flip/core/common';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable()
export class ConfigurationHolder {

  private configuration: Configuration;
  private overrideTouchMode: boolean;
  private mobileDetect: any;
  configurationSubject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public readonly configuration$: Observable<any> = this.configurationSubject.asObservable();
  url: URL;
  scopeKey: string;
  baseUrl: string;
  assetId: string;
  isDraft: string;

  constructor(@Inject(DOCUMENT) private document: any,
              @Inject(WINDOW) private window: any) {
    this.configuration = window.JSON.parse(window.localStorage.getItem('config')) || window['data'].config;
    // override configuration when in preview mode
    if (window.location.search.includes('preview')) {
      this.configuration = window.JSON.parse(window.sessionStorage.getItem('config')) || this.configuration;
      this.configuration.options.loader = false;
      this.configuration.options.tracking = false;
    }

    this.overrideTouchMode = window.location.search.includes('touchMode');
    this.mobileDetect = new MobileDetect(window.navigator.userAgent);
  }

  get pageMarkers(): PageMarker[] {
    return this.configuration.options.pageMarkers;
  }

  get config(): Configuration {
    return this.configuration;
  }

  get autoplay(): number {
    return this.configuration?.autoplay;
  }

  get design(): Design {
    return this.configuration?.design;
  }

  get features(): Features {
    return this.configuration?.features;
  }

  get layout(): Layout {
    return this.configuration?.layout;
  }

  get legal(): Legal {
    return this.configuration?.legal;
  }

  get options(): Options {
    return this.configuration?.options;
  }

  get shop(): Shop {
    return this.configuration?.shop;
  }

  get debugMode(): boolean {
    return this.window.JSON.parse(this.window.localStorage.getItem('debug')) === true;
  }

  get touchMode(): boolean {
    if (this.overrideTouchMode) {
      this.document.body.style.zoom = '80%'; // for viewer preview in creator
      return true;
    }

    return this.isMobile;
  }

  get isFirefox(): boolean {
    return this.window.navigator.userAgent.toLowerCase().includes('firefox');
  }

  get isIE(): boolean {
    return !!this.document.documentMode && !!this.window.MSInputMethodContext;
  }

  get isMac(): boolean {
    return this.window.navigator.platform.toLowerCase().includes('mac');
  }

  get isiOS(): boolean {
    return this.mobileDetect.os() === 'iOS';
  }

  get isiPhoneX(): boolean {
    const ratio: number = this.window.devicePixelRatio || 1;
    const screen: Dimensions = {
      width: this.window.screen.width * ratio,
      height: this.window.screen.height * ratio
    };
    return this.isiOS && screen.width === 1125 && screen.height === 2436;
  }

  get isPhone(): boolean {
    return this.mobileDetect.phone();
  }

  get isTablet(): boolean {
    return this.mobileDetect.tablet();
  }

  get isWebApp(): boolean {
    return this.window.location.search.includes('webApp');
  }

  get isMobile(): boolean {
    return this.mobileDetect.mobile();
  }

}
