import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { SpoUrlService } from './spo-url.service';
import { catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
const FileSaver = require('file-saver');

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  assetId: string;
  baseUrl: string;

  constructor(private httpClient: HttpClient, spoUrlService: SpoUrlService) {
    this.assetId = spoUrlService.assetId;
    this.baseUrl = spoUrlService.backendUrl;
  }

  private concatSelectedPagesNumbers(selectedPages: number[]): string{
    let selectedNumbers = '';
    selectedPages.sort(function(a, b){return a-b}).forEach( (page, index) => {
      if(index === 0) {
        selectedNumbers += page;
      }
       else{
       selectedNumbers += ',' + page;
      }
    })
    return selectedNumbers;
  }

  printDocument(selectedNumbers: number[], isSelectedAll: boolean): Observable<any>  {
    if (isSelectedAll) {
      return this.getPdfFromContent();
    }
    const url = `${this.baseUrl}pdf/${this.assetId}/merge`;
    const body = { pages : this.concatSelectedPagesNumbers(selectedNumbers) };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpClient.post(url, body, { headers: headers, observe: 'response', responseType: 'blob' as 'json' }).pipe(
      catchError(err => {
        if (err.status !== 200) {
          const getUrl = `${this.baseUrl}pdf/${this.assetId}/merge?pages=${this.concatSelectedPagesNumbers(selectedNumbers)}`;
          return this.httpClient.get(getUrl, { responseType: 'blob' });
        } else {
          throw err;
        }
      }),
      map((response: any) => {
        if (response instanceof HttpResponse) {
          return response.body;
        }
        return response;
      })
    );
  }

  getPdfFromContent(): Observable<any> {
    return this.httpClient.get('./page2flip_document.pdf', {responseType: "blob"});
  }

  download(selectedNumbers: number[], isSelectedAll: boolean): void {
    if (isSelectedAll) {
      this.getPdfFromContent().subscribe(pdf => FileSaver.saveAs(pdf, this.assetId.toString()));
      return
    }
    const postUrl = `${this.baseUrl}pdf/${this.assetId}/merge?download=true`;
    const body = {
      pages: this.concatSelectedPagesNumbers(selectedNumbers)
    };

    this.httpClient.post(postUrl, body, { responseType: 'blob' }).subscribe(response => {
      FileSaver.saveAs(response, this.assetId.toString());
    }, error => {
      if(error.status !== 200){
        const getUrl = `${this.baseUrl}pdf/${this.assetId}/merge?pages=${this.concatSelectedPagesNumbers(selectedNumbers)}&download=true`;
        this.httpClient.get(getUrl, { responseType: 'blob' }).subscribe(response => {
          FileSaver.saveAs(response, this.assetId.toString());
        });
      }
    });
  }

}
