import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AnalyticsConfiguration } from '../../interfaces/space-one/analytics-configuration';

@Component({
  selector: 'p2f-cookie-consent-dialog',
  templateUrl: './cookie-consent-dialog.component.html',
  styleUrls: [ './cookie-consent-dialog.component.css' ]
})
export class CookieConsentDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { analyticsConfiguration: AnalyticsConfiguration },
    private dialogRef: MatDialogRef<CookieConsentDialogComponent>
  ) { }

  ngOnInit(): void {
    this.setResponsiveWidth();
  }

  private setResponsiveWidth(): void {
    if (window.innerWidth <= 600) {
      this.dialogRef.updateSize('100%', 'auto');
    } else {
      this.dialogRef.updateSize('40%', 'auto');
    }
  }

}
