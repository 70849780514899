import { Injectable } from '@angular/core';
import { Publication } from '@page2flip/core/common';

@Injectable({
  providedIn: 'root'
})
export class SpoUrlService {

  readonly backendUrl: string;

  readonly apiUrl: string;

  readonly scopeKey: string;

  readonly assetId: string;


  constructor() {
    const publication: Publication = window['data'].publication;
    this.backendUrl = this.tryFetch(() => publication.backendUrl ?? window.location.href.split('scope/')[0]);
    this.apiUrl = this.tryFetch(() => this.backendUrl?.endsWith("/api/") ? this.backendUrl : this.backendUrl + "/api/")
    this.scopeKey = this.tryFetch(() => publication.spaceKey ?? window.location.href.split('scope/')[1]?.split('/')[0]);
    this.assetId = this.tryFetch(() => publication.assetId ? publication.assetId.toString() ?? window.location.href.split('/asset/')[ 1 ]?.split('/')[ 0 ] ?? '' : '');
  }

  private tryFetch(fetcher: () => string) {
    try {
      return fetcher();
    } catch (e) {
      console.error(e);
      return '';
    }
  }

}
