import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {NxModule} from '@nrwl/angular';
import {JQUERY, jqueryFactory, PerfectScrollbarConfig, WINDOW, windowFactory} from '@page2flip/core';
import {DynamicComponentLoaderModule} from '@page2flip/dcl';
import {EmbedVideo} from 'ngx-embed-video';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';

import {AppComponent} from './app.component';
import {BaseModule} from './base/base.module';
import {bugsnagFactory} from './bugsnag.factory';
import {dynamicComponents} from './dynamic.components';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '../environments/environment';
import {DraftsService} from "./core/services/drafts.service";
import {UrlService} from "./core/services/url.service";
import {forkJoin, of} from "rxjs";
import {catchError} from 'rxjs/operators';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, environment.production ? `${environment.deployUrl}assets/i18n/` : "/assets/i18n/");
}

@NgModule({
  bootstrap: [
    AppComponent
  ],
  declarations: [
    AppComponent
  ],
  imports: [
    BaseModule,
    DynamicComponentLoaderModule.forRoot(dynamicComponents),
    EmbedVideo.forRoot(),
    NxModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeServices,
      deps: [DraftsService, UrlService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useFactory: bugsnagFactory,
    },
    {
      provide: JQUERY,
      useFactory: jqueryFactory,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: PerfectScrollbarConfig,
    },
    {
      provide: WINDOW,
      useFactory: windowFactory,
    }
  ]
})
export class AppModule {
}

export function initializeServices(draftsService: DraftsService, urlService: UrlService) {
  return () => {
    return new Promise<void>((resolve, reject) => {
      const requests = [];
      requests.push(draftsService.getDraftConfiguration().pipe(
        catchError(error => {
          return of(error);
        })
      ));
      requests.push(draftsService.getDraftHotspots().pipe(
        catchError(error => {
          return of(error);
        })
      ));
      forkJoin(requests).subscribe(
        (responses: any[]) => {
          const errorMessages = responses.map(response => {
            if (response instanceof Error) {
              return response.message;
            } else if (response.status === 200) {
              return response
            } else {
              return response;
            }
          });
          const hasError = errorMessages.some(errorMessage => errorMessage !== null);
          if (hasError) {
            urlService.setData(errorMessages[0], errorMessages[1]);
            resolve();
          } else {
            resolve();
          }
        },
        error => {
          reject(error);
        }
      );
    });
  };
}
