import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  readonly draft: string;

  constructor() {
    this.draft = this.isDraft(new URL(window.location.toString()));
  }

  setData(configData, hotspotsData) {
    if (this.draft === 'true') {
      window['data'].config = configData;
      window['data'].hotspots = hotspotsData;
    }
  }

  isDraft(url: URL): string {
    if (!url?.searchParams) {
      return 'false';
    }
    const draftParam = url.searchParams.get('draft');
    if (!draftParam) {
      return 'false';
    }
    const splitDraftParam = draftParam.split('&');
    const draftValue = splitDraftParam[0];
    const sanitizedValue = draftValue.replace('/', '');
    if (sanitizedValue.trim() !== '') {
      return sanitizedValue;
    } else {
      return 'false';
    }
  }

}
