import {DOCUMENT} from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  HostListener,
  Inject, Output,
  ViewChild
} from '@angular/core';
import {HammerGestureConfig} from '@angular/platform-browser';
import {debounce, PageMarker} from '@page2flip/core/common';
import {fromEvent, Subscription} from 'rxjs';
import {takeWhile} from 'rxjs/operators';

import {ConfigurationHolder} from '../../core/services/configuration-holder.service';
import {Constants} from '../../core/services/constants.service';
import {Designer} from '../../core/services/designer.service';
import {DocumentService} from '../../core/services/document.service';
import {ImageLoader} from '../../core/services/image-loader.service';
import {EventListener} from '../../core/services/event-listener.service';
import {TranslateService} from '@ngx-translate/core';
import {PageDimensions} from "@page2flip/core/common/src/lib/interfaces/dimensions.interface";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'p2f-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements AfterViewInit {

  @ViewChild('pages') pagesElementRef: ElementRef;
  @ViewChild('shadow') private shadowElementRef: ElementRef;

  /** Page markers. */
  readonly pageMarkers: PageMarker[] = this.config.pageMarkers || [];

  /** Current page number */
  currentPageNumber: number;

  /** Whether or not touch mode is enabled. */
  readonly touchMode: boolean = this.config.touchMode;

  /** Whether or not transparency is enabled. */
  readonly transparency: boolean = this.config.design.transparency;

  /** Whether or not page comparison is active. */
  comparingPages = false;

  @Output() swipeLeft: EventEmitter<any> = new EventEmitter<any>();
  @Output() swipeRight: EventEmitter<any> = new EventEmitter<any>();

  numberOfPages: number;
  private pageDimensions: PageDimensions[];
  private pagesElement: HTMLDivElement;
  private shadowElement: HTMLDivElement;
  visiblePages: number[];
  private visiblePagesSubscription: Subscription;
  private comparePagesSubscription: Subscription;

  pageNumbers: number[];
  isMobile = false;
  isViewerInIframe: boolean;

  constructor(@Inject(DOCUMENT) private document: any,
              public config: ConfigurationHolder,
              private designer: Designer,
              public doc: DocumentService,
              private loader: ImageLoader,
              private events: EventListener,
              private deviceDetector: DeviceDetectorService,
              private translate: TranslateService) {
    this.doc.currentPageNumber.subscribe(currentPageNumber => this.currentPageNumber = currentPageNumber);
    this.numberOfPages = doc.numberOfPages;
    this.pageNumbers = doc.turnJsEnabled ? Array.from(new Array(this.numberOfPages), (v, k) => k + 1) : [];
    this.isMobile = this.deviceDetector.isMobile();
    this.isViewerInIframe = window.location.href.includes('iframe');
  }

  ngAfterViewInit() {
    this.pagesElement = this.pagesElementRef.nativeElement;
    this.shadowElement = this.shadowElementRef.nativeElement;
    this.doc.pageDimensions.subscribe(dimensions => {
      this.pageDimensions = dimensions;
      this.doc.initialized ?
        this.doc.setPageLayout() :
        this.doc.init();

      if (!this.visiblePagesSubscription) {
        this.visiblePagesSubscription = this.doc.visiblePages.subscribe(pages => {
          this.translate.get('notify-message', {message: pages.join('-')}).subscribe(response => this.doc.notify(response));

          this.visiblePages = pages;
          this.comparingPages = false;

          if (!this.doc.turnJsEnabled) {
            this.pagesElement.style.position = this.config.isIE ? 'relative' : 'absolute';
            this.pagesElement.style.width = this.doc.scaledWidth() + 'px';
            this.pagesElement.style.height = this.doc.scaledHeight() + 'px';
            const surroundingPages: number[] = [];

            for (let i = pages[0] - pages.length; i <= pages[pages.length - 1] + pages.length; i++) {
              surroundingPages.push(i);
            }

            this.pageNumbers = surroundingPages
              .filter(page => page <= this.doc.numberOfPages)
              .filter(page => page > 0);

            requestAnimationFrame(() => {
              this.document.querySelectorAll('p2f-document > .pages > .page').forEach((pageElement: HTMLDivElement) => {
                const pageNumber: number = parseInt(pageElement.getAttribute('page'), 0);
                pageElement.style.backgroundImage = this.loader.getPageImageUrl(pageNumber, this.doc.imageQuality);
                pageElement.style.position = 'absolute';
                pageElement.style.right = pageNumber % 2 === 0 ? 'auto' : '0';
                pageElement.style.width = this.findPage(pageNumber).width + 'px';
                pageElement.style.height = this.findPage(pageNumber).height + 'px';
                pageElement.style.visibility = this.visiblePages.includes(pageNumber) ? 'visible' : 'hidden';
              });
            });
          }

          requestAnimationFrame(() => {
            this.centerPages();
            this.setPageMarkerWidth();
            this.setPageMarkerHeight();
            this.setNavigationButtonsPosition();
            this.setShadowDimensions();
            this.loadPageImages();
          });
        });
      }

      if (!this.comparePagesSubscription) {
        this.comparePagesSubscription = this.doc.comparePages.subscribe(pages => {
          this.translate.get('notify-message', {message: pages.join('-')}).subscribe(response => this.doc.notify(response));

          this.visiblePages = pages;
          this.comparingPages = true;

          if (!this.doc.turnJsEnabled) {
            this.pagesElement.style.position = this.config.isIE ? 'relative' : 'absolute';
            this.pagesElement.style.width = this.doc.scaledWidth() + 'px';
            this.pagesElement.style.height = this.doc.scaledHeight() + 'px';

            this.pageNumbers = pages;

            let count = 1;

            requestAnimationFrame(() => {
              this.document.querySelectorAll('p2f-document > .pages > .page').forEach((pageElement: HTMLDivElement) => {
                const pageNumber: number = parseInt(pageElement.getAttribute('page'), 0);
                pageElement.style.backgroundImage = this.loader.getPageImageUrl(pageNumber, this.doc.imageQuality);
                pageElement.style.position = 'absolute';
                pageElement.style.right = count === 1 ? 'auto' : '0';
                pageElement.style.width = this.findPage(pageNumber).width + 'px';
                pageElement.style.height = this.findPage(pageNumber).height + 'px';
                pageElement.style.visibility = this.visiblePages.includes(pageNumber) ? 'visible' : 'hidden';
                count++;
              });
            });
          }

          requestAnimationFrame(() => {
            this.centerPages();
            this.setNavigationButtonsPosition();
            this.setShadowDimensions();
            this.loadPageImages();
          });
        });
      }
    });

    this.doc.imageQualityObs.subscribe(newImageQuality => {
      if (newImageQuality) {
        if (!this.doc.turnJsEnabled) {
          this.document.querySelectorAll('p2f-document > .pages > .page').forEach((pageElement: HTMLDivElement) => {
            const pageNumber: number = parseInt(pageElement.getAttribute('page'), 0);
            pageElement.style.backgroundImage = this.loader.getPageImageUrl(pageNumber, newImageQuality);
          });
        } else {
          this.loadPageImages();
        }
      }
    })

    // remove splash screen
    const splash: HTMLDivElement = this.document.querySelector('.splash');
    if (splash) splash.parentElement.removeChild(splash);

    if (this.config.touchMode) {
      const hammerConfig = new HammerGestureConfig();
      const pageContainer = this.document.querySelectorAll('p2f-document > .pages')[0];
      const hammer = hammerConfig.buildHammer(pageContainer);

      const hmManager = new Hammer.Manager(pageContainer);
      const doubleTap = new Hammer.Tap({event: 'doubletap', taps: 2});
      hmManager.add([doubleTap]);

      fromEvent(hammer, 'swipe')
        .pipe(takeWhile(() => true))
        .subscribe((res: any) => {
          if (this.doc.currentZoomFactor < 1.001) {
            res.deltaX < 0 ? this.swipeLeft.emit() : this.swipeRight.emit();
          }
        });


      fromEvent(hammer, 'doubletap')
        .pipe(takeWhile(() => true))
        .subscribe((res: any) => {
          this.events.toggleZoom(res);
        });
    }

    if (this.config.autoplay) {
      setInterval(() => {
        if (!this.doc.autoplayPaused) {
          if (this.currentPageNumber === this.doc.numberOfPages - 1 || this.currentPageNumber === this.doc.numberOfPages) {
            this.doc.firstPage();
          } else {
            this.doc.nextPage();
          }
        }
      }, this.config.autoplay);
    }
  }

  private findPage(pageNumber: number) {
    return this.pageDimensions.find(pageDimension => pageDimension.pageNumber === pageNumber) ?? this.pageDimensions[0];
  }

  @HostListener('window:resize')
  @debounce(Constants.resizeEventThreshold)
  onResize() {
    this.centerPages();
    this.setShadowDimensions();
    this.loadPageImages();
    this.setNavigationButtonsPosition();
  }

  private centerPages() {
    if (this.doc.turnJsEnabled) {
      requestAnimationFrame(() => {
        const direction: number = this.visiblePages[0] === 1 ? -1 : 1;
        this.pagesElement.style.left = this.doc.numberOfVisiblePages === 2 && this.visiblePages.length === 1 ? direction * this.pagesElement.clientWidth / 4 + 'px' : '0';
        this.alignPageMarkers();
      })
    } else {
      this.alignPageMarkers();
    }
  }

  private alignPageMarkers() {
    this.pageMarkers.forEach((pm, index) => {
      const page = Array.from((document.getElementById('pages') as HTMLElement).children).find(child => this.visiblePages.includes(Number(child.getAttribute('page'))))
      const pageMarker = document.getElementById(`pm${index}`);
      if (pageMarker) {
        pageMarker.style.top = -page.clientHeight / 2 + index * 50 + 50 + 'px';
        if (!this.doc.turnJsEnabled) {
          pageMarker.style.top = Number(pageMarker.style.top.split('px')[0]) - 50 + 'px';
        }
        if (this.visiblePages.length === 1) {
          if (this.visiblePages.includes(pm.page)) {
            pageMarker.style.left = page.clientWidth / 2 - 20 + 'px';
            pageMarker.style.paddingLeft = '20px';
          } else {
            if (this.currentPageNumber === this.numberOfPages) {
              pageMarker.style.left = -page.clientWidth / 2 - this.config.layout.pageMarkerWidth - 18 + 'px';
              pageMarker.style.paddingLeft = 'unset';
              pageMarker.style.paddingRight = this.currentPageNumber === pm.page ? '20px' : 'unset';
            } else {
              pageMarker.style.left = page.clientWidth / 2 + 'px';
              pageMarker.style.paddingLeft = 'unset';
              pageMarker.style.paddingRight = 'unset';
            }
          }
        } else {
          if (this.visiblePages.every(page => pm.page < page)) {
            pageMarker.style.left = -page.clientWidth - this.config.layout.pageMarkerWidth - 18 + 'px';
            pageMarker.style.paddingLeft = 'unset';
            pageMarker.style.paddingRight = 'unset';
          } else if (this.visiblePages[0] === pm.page) {
            pageMarker.style.left = -page.clientWidth - this.config.layout.pageMarkerWidth - 18 + 'px';
            pageMarker.style.paddingRight = '20px';
          } else if (this.visiblePages[1] === pm.page) {
            pageMarker.style.left = page.clientWidth - 20 + 'px';
            pageMarker.style.paddingLeft = '20px';
            pageMarker.style.paddingRight = 'unset';
          } else {
            pageMarker.style.left = page.clientWidth + 'px';
            pageMarker.style.paddingLeft = 'unset';
            pageMarker.style.paddingRight = 'unset';
          }
        }
      }
    })
  }

  private setNavigationButtonsPosition() {
    if (!this.config.touchMode) {
      const viewerWidth: number = this.document.querySelector('p2f-viewer').clientWidth;
      const documentWidth: number = this.doc.scaledWidth();
      const diff: number = Math.floor((viewerWidth - documentWidth) / 2 - (this.config.options.pageMarkers && !this.config.layout.pageFlipAnimation ? this.config.layout.pageMarkerWidth + 20 : 0));

      requestAnimationFrame(() => {
        if (this.document.querySelector('.previous') && this.document.querySelector('.next')) {
          this.document.querySelector('.previous').style.left = diff - 62 + 'px';
          this.document.querySelector('.next').style.right = diff - 62 + 'px';
        } else {
          this.setNavigationButtonsPosition();
        }
      });
    }
  }

  private setShadowDimensions() {
    this.shadowElement.style.backgroundSize = this.visiblePages.length === 1 ? '50%' : '25%';
    this.shadowElement.style.width = (this.doc.scaledWidth()) - 1 + 'px'; // page background images 1px x-offset
    this.shadowElement.style.height = this.doc.scaledHeight() + 'px';

    if (this.config.isIE) { //Don't think IE exists anymore
      this.shadowElement.style.top = '0';
      this.shadowElement.style.left = this.doc.numberOfVisiblePages === 2 && this.visiblePages.length === 1 && this.doc.turnJsEnabled ? this.pageDimensions[0].width / 2 + 'px' : '0';
    }
  }

  private loadPageImages() {
    if (this.doc.turnJsEnabled) {
      this.document.querySelectorAll('.page-wrapper').forEach((pageWrapperElement: HTMLDivElement) => {
        const pageNumber: number = parseInt(pageWrapperElement.getAttribute('page'), 0);
        const pageElement: HTMLDivElement = this.document.querySelector('.page.p' + pageNumber);
        pageElement.style.backgroundImage = this.loader.getPageImageUrl(pageNumber, this.doc.imageQuality);
      });
    }
  }

  /**
   * Sets the background of a page marker.
   *
   * @param color Marker background color.
   */
  setPageMarkerBackground(color: string) {
    if (color) {
      if (this.transparency) {
        if (color.includes('#')) {
          return this.designer.hexToRgba(color, 0.85);
        } else {
          return color;
        }
      } else {
        return color;
      }
    }
  }

  /**
   * Sets the width of the page markers.
   */
  setPageMarkerWidth() {
    this.document.querySelectorAll('.page-marker-shadow').forEach(pageMarkerShadow => pageMarkerShadow.style.width = this.config.layout.pageMarkerWidth + 20 + 'px');
    this.document.querySelectorAll('.page-marker').forEach(pageMarker => pageMarker.style.width = this.config.layout.pageMarkerWidth + 20 + 'px');
  }

  /**
   * Sets the height of the page markers.
   */
  setPageMarkerHeight() {
    const padding = 10;
    const maxHeight = 40;
    const availableHeight: number = (this.doc.scaledHeight() - (this.pageMarkers.length * padding)) / this.pageMarkers.length;
    const height: number = availableHeight < maxHeight ? availableHeight : maxHeight;

    this.document.querySelectorAll('.page-marker-shadow').forEach(pageMarkerShadow => pageMarkerShadow.style.height = height + 'px');
    this.document.querySelectorAll('.page-marker').forEach((pageMarker, index) => {
      pageMarker.style.height = height + 'px';
    });

    if (height <= 14) { // font size
      this.document.querySelectorAll('.page-marker-shadow').forEach(pageMarkerShadow => pageMarkerShadow.style.display = 'none');
      this.document.querySelectorAll('.page-marker').forEach(pageMarker => pageMarker.style.display = 'none');
    } else {
      this.document.querySelectorAll('.page-marker-shadow').forEach(pageMarkerShadow => pageMarkerShadow.style.display = 'block');
      this.document.querySelectorAll('.page-marker').forEach(pageMarker => pageMarker.style.display = 'flex');
    }
  }

  /**
   * Navigates to the given page.
   *
   * @param pageNumber  Page number.
   */
  gotoPage(pageNumber: number) {
    this.doc.resetZoom();
    this.doc.gotoPage(pageNumber);
  }

}
